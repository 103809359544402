import React from "react";
import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import RTL from "./layouts/full-layout/customizer/RTL";
import ThemeSettings from "./layouts/full-layout/customizer/ThemeSettings";
import Router from "./routes/Router";
import "react-perfect-scrollbar/dist/css/styles.css";



/**
 * Functional component representing the main App.
 * It sets up routing using the useRoutes hook with the Router component,
 * applies the theme settings using ThemeSettings,
 * and accesses customizer state using useSelector from CustomizerReducer.
 * @returns JSX element representing the main App with routing, theme, and customizer settings.
 */
const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  /* The `return` statement in the `App` component is returning a JSX (JavaScript XML) expression. */
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {routing}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
