
let Menuitems = [
  {
    title: 'Inicio',
    icon: 'home',
    href: '/home',
  },

  {
    title: 'Chat',
    icon: 'message-circle',
    href: '/chat',
    collapse: true,
    children: [

      {
        title: 'AutoExpertos',
        icon: 'message-circle',
        href: '/chat/autoexpertos',

      }
    ]
  },


  {
    title: 'Calendario',
    icon: 'calendar',
    href: '/calendar',
  },
  {
    title: 'Servicios',
    icon: 'truck',
    href: '/services',
    collapse: false,
    children: [
      {
        title: 'Agendado',
        icon: 'corner-down-right',
        href: '/services/agendados',
      },
      {
        title: 'En Traslado',
        icon: 'corner-down-right',
        href: '/services/traslado',
      },
      {
        title: 'En Taller',
        icon: 'corner-down-right',
        href: '/services/taller',
      },

      {
        title: 'Listo Retirar',
        icon: 'corner-down-right',
        href: '/services/retirar',
      },
      {
        title: 'Finalizado',
        icon: 'corner-down-right',
        href: '/services/finalizado',
      },
    ],
  },

  // {
  //   title: 'Agendado',
  //   icon: 'truck',
  //   href: '/services/agendados',
  // },
  // {
  //   title: 'En Taller',
  //   icon: 'truck',
  //   href: '/services/taller',
  // },
  // {
  //   title: 'Retirar',
  //   icon: 'truck',
  //   href: '/services/retirar',
  // },
  // {
  //   title: 'Finalizado',
  //   icon: 'truck',
  //   href: '/services/finalizado',
  // },

  // {
  //   title: 'Pagos',
  //   icon: 'truck',
  //   href: '#',
  // },



  {
    title: 'Estadisticas',
    icon: 'archive',
    href: '/stat',
  },

  {
    title: 'Reporteria',
    icon: 'archive',
    href: '/reporting',
  },

  {
    title: 'Cerrar Sesión',
    icon: 'log-out',
    href: '/auth/close',
  },
];


// console.log("ACTIVA ADICIONALES", process.env.REACT_APP_ADDITIONAL, typeof(process.env.REACT_APP_ADDITIONAL))
if (process.env.REACT_APP_ADDITIONAL === "true") {
  Menuitems = [
    {
      title: 'Inicio',
      icon: 'home',
      href: '/home',
    },
    // {
    //   title: 'Chat',
    //   icon: 'message-circle',
    //   href: '/chat',
    //   collapse: true,
    //   children: [

    //     {
    //       title: 'AutoExpertos',
    //       icon: 'message-circle',
    //       href: '/chat/autoexpertos',

    //     }
    //   ]
    // },

    {
      title: 'Calendario',
      icon: 'calendar',
      href: '/calendar',
    },
    {
      title: 'Servicios',
      icon: 'truck',
      href: '/services',
      collapse: false,
      children: [
        {
          title: 'Agendado',
          icon: 'corner-down-right',
          href: '/services/agendados',
        },
        {
          title: 'En Traslado',
          icon: 'corner-down-right',
          href: '/services/traslado',
        },
        {
          title: 'En Taller',
          icon: 'corner-down-right',
          href: '/services/taller',
        },
        {
          title: 'Adicionales',
          icon: 'corner-down-right',
          href: '/services/adicionales',
        },


        {
          title: 'Listo Retirar',
          icon: 'corner-down-right',
          href: '/services/retirar',
        },
        {
          title: 'Finalizado',
          icon: 'corner-down-right',
          href: '/services/finalizado',
        },
      ],
    },

    // {
    //   title: 'Agendado',
    //   icon: 'truck',
    //   href: '/services/agendados',
    // },
    // {
    //   title: 'En Taller',
    //   icon: 'truck',
    //   href: '/services/taller',
    // },
    // {
    //   title: 'Retirar',
    //   icon: 'truck',
    //   href: '/services/retirar',
    // },
    // {
    //   title: 'Finalizado',
    //   icon: 'truck',
    //   href: '/services/finalizado',
    // },

    // {
    //   title: 'Pagos',
    //   icon: 'truck',
    //   href: '#',
    // },

    {
      title: 'Estadisticas',
      icon: 'archive',
      href: '/stat',
    },

    {
      title: 'Reporteria',
      icon: 'archive',
      href: '/reporting',
    },

    {
      title: 'Cerrar Sesión',
      icon: 'log-out',
      href: '/auth/close',
    },
  ];
}

const nameApp = 'Talleres';
const urlEndPoint = process.env.REACT_APP_API;
const forceLogin = true;

export {  Menuitems, nameApp, urlEndPoint };
