import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import PrivateRouter from './Private';

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import('../layouts/full-layout/FullLayout'))
);
const BlankLayout = Loadable(
  lazy(() => import('../layouts/blank-layout/BlankLayout'))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/login4')));
const Recovery = Loadable(lazy(() => import('../views/authentication/ResetPassword')));

const Close = Loadable(lazy(() => import('../views/authentication/close')));

/* ****Pages***** */
const Dashboard = Loadable(
  lazy(() => import('../views/dashboards/Dashboard1'))
);




const Calendario = Loadable(
  lazy(() => import('../views/autoexpertos/MyCalendar/ACalendar'))
);

const Scheduled = Loadable(lazy(() => import('../views/dashboards/Scheduled')));
const InWorkshop = Loadable(
  lazy(() => import('../views/dashboards/InWorkshop'))
);

const Additional = Loadable(
  lazy(() => import('../views/dashboards/Additionnal'))
);


const Withdraw = Loadable(lazy(() => import('../views/dashboards/Withdraw')));
const Finalized = Loadable(lazy(() => import('../views/dashboards/Finalized')));
const Moving = Loadable(lazy(() => import('../views/dashboards/Moving')));
const Reporting = Loadable(lazy(() => import('../views/dashboards/Reporting')));

const Metabase = Loadable(lazy(() => import('../views/dashboards/Metabase')));
const BookingView = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/BookingView'))
);

/* ****Routes***** */
const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/auth/login" /> },
      {
        path: '/home',
        exact: true,
        element: <PrivateRouter component={<Dashboard />} />,
      },

      {
        path: '/calendar',
        exact: true,
        element: (
          <PrivateRouter component={<Calendario />} profile={[0, 1, 2, 4, 5]} />
        ),
      },

     


      {
        path: '/services/agendados',
        exact: true,
        element: (
          <PrivateRouter component={<Scheduled />} profile={[0, 1, 2, 4, 5]} />
        ),
      },

      {
        path: '/services/traslado',
        exact: true,
        element: (
          <PrivateRouter component={<Moving />} profile={[0, 1, 2, 4, 5]} />
        ),
      },

      {
        path: '/services/taller',
        exact: true,
        element: (
          <PrivateRouter component={<InWorkshop />} profile={[0, 1, 2, 4, 5]} />
        ),
      },

      {
        path: '/services/adicionales',
        exact: true,
        element: (
          <PrivateRouter component={<Additional />} profile={[0, 1, 2, 4, 5]} />
        ),
      },




      {
        path: '/services/retirar',
        exact: true,
        element: (
          <PrivateRouter component={<Withdraw />} profile={[0, 1, 2, 4, 5]} />
        ),
      },

      {
        path: '/services/finalizado',
        exact: true,
        element: (
          <PrivateRouter component={<Finalized />} profile={[0, 1, 2, 4, 5]} />
        ),
      },

      {
        path: '/stat',
        exact: true,
        element: (
          <PrivateRouter component={<Reporting />} profile={[0, 1, 2, 4, 5]} />
        ),
      },

      {
        path: '/reporting',
        exact: true,
        element: (
          <PrivateRouter component={<Metabase  uuid={166}/>} profile={[0, 1, 2, 4, 5]} />
        ),
      },

      {
        path: '/op/view/:idMaintenance/:idRef',
        element: (
          <PrivateRouter
            component={<BookingView />}
            profile={[0, 1, 2, 4, 5]}
          />
        ),
      },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'close', element: <Close /> },
      { path: 'recovery/:token', element: <Recovery /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
