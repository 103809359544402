import axios from 'axios';

import {
  createDecipheriv,
  createCipheriv,
  randomBytes,
} from 'crypto-browserify';

const TokenString = '';

/**
 * The `callApi` function is an asynchronous function that makes an HTTP request to a specified URL
 * with a specified method, token, and body, and returns the response data.
 * @param url - The `url` parameter is the endpoint URL where the API request will be sent.
 * @param method - The `method` parameter is the HTTP method to be used for the API call, such as
 * "GET", "POST", "PUT", "DELETE", etc.
 * @param token - The `token` parameter is a string that represents the authentication token used for
 * authorization. It is optional and can be provided if the API requires authentication.
 * @param body - The `body` parameter is the data that you want to send in the request body. It should
 * be an object that will be converted to JSON before sending the request.
 * @returns The function `callApi` returns a Promise that resolves to the response from the API call.
 */
export const callApi = async (url, method, token, body) => {
  // console.log('CallApi', url);
  const localToken = validateToken(localStorage.getItem('session'));
  try {
    if (process.env.REACT_APP_AAA === 'true') {
      body = { data: encryptedaes256cbc(body, localToken) };
    }
  } catch (error) {
    // console.log("EREA", error);
  }

  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
  }).catch((error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 response here
      return { status: false };
    } else {
      // Handle other errors
      return { status: false, session: false };
    }
  });

  if (!responsecall.status) {
    return responsecall;
  } else {
    const json = responsecall.data;
    if (process.env.REACT_APP_AAA === 'true') {
      return await descryptedaes256cbc(json.data, localToken);
    }

    return json;
  }
};

/**
 * The function `callSendFornData` is an asynchronous function that sends a FormData object to a
 * specified URL with an optional token for authorization.
 * @param url - The `url` parameter is the endpoint URL where you want to send the form data.
 * @param token - The `token` parameter is a string that represents an authentication token. It is used
 * to authorize the request to the server. If a token is provided, it will be included in the request
 * headers as a bearer token. If no token is provided, the Authorization header will be empty.
 * @param formData - The `formData` parameter is an instance of the `FormData` object. It is used to
 * send data in a format that is compatible with HTML forms. It can be used to send files and key-value
 * pairs of data.
 * @returns the data from the response of the POST request made using axios.
 */
export const callSendFornData = async (url, token, formData) => {
  const localToken = validateToken(localStorage.getItem('session'));

  const config = {
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'multipart/form-data', // Esencial para enviar FormData
    },
  };

  try {
    const response = await axios.post(url, formData, config);

    const json = response.data;
    if (process.env.REACT_APP_AAA === 'true') {
      return await descryptedaes256cbc(json.data, localToken);
    }

    return json;

    return response.data;
  } catch (error) {
    console.error('Error al subir la foto:', error);
    throw error;
  }
};

/**
 * The function `callApiInit` is an asynchronous function that makes an API call using the specified
 * URL, method, token, and body, and returns the response data as JSON.
 * @param url - The `url` parameter is the endpoint URL where the API request will be sent.
 * @param method - The `method` parameter is the HTTP method to be used for the API call, such as
 * 'GET', 'POST', 'PUT', 'DELETE', etc.
 * @param token - The `token` parameter is a string that represents an authentication token. It is used
 * to authorize the API request. If a token is provided, it will be included in the request headers as
 * a bearer token. If no token is provided, the Authorization header will be empty.
 * @param body - The `body` parameter is the data that you want to send in the request body. It should
 * be an object that will be converted to JSON using `JSON.stringify()` before sending the request.
 * @returns the JSON response from the API call.
 */
export const callApiInit = async (url, method, token, body) => {
  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
  });

  const json = responsecall.data;

  return json;
};

/**
 * The function `callApiLogin` is used to make an API call for logging in, with optional encryption and
 * decryption of the request and response data.
 * @param url - The `url` parameter is the URL of the API endpoint that you want to call. It specifies
 * the location where the API request should be sent.
 * @param method - The `method` parameter is the HTTP method to be used for the API call, such as
 * "GET", "POST", "PUT", "DELETE", etc.
 * @param token - The `token` parameter is a string representing an authentication token. It is used to
 * authorize the API request. If a token is provided, it will be included in the request headers as a
 * Bearer token. If no token is provided, the Authorization header will be empty.
 * @param body - The `body` parameter is an object that contains the following properties:
 * @returns the response data from the API call. If the `REACT_APP_AAA` environment variable is set to
 * `'true'`, the response data is decrypted using the `descryptedaes256cbc` function before being
 * returned.
 */
export const callApiLogin = async (url, method, token, body) => {
  const localToken = validateToken(localStorage.getItem('session'));
  if (process.env.REACT_APP_AAA === 'true') {
    body = {
      username: encryptedaes256cbcTextPlain(body.username, localToken),
      password: encryptedaes256cbcTextPlain(body.password, localToken),
      method: encryptedaes256cbcTextPlain(body.method, localToken),
    };
  }
  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
  });

  const json = responsecall.data;

  if (process.env.REACT_APP_AAA === 'true') {
    return await descryptedaes256cbc(json.data, localToken);
  }
  return json;
};

/**
 * The function `callApiRegister` is an asynchronous function that makes an API call to register a
 * user, encrypting certain fields if a specific environment variable is set to true.
 * @param url - The `url` parameter is the endpoint URL where the API request will be sent.
 * @param method - The `method` parameter is the HTTP method to be used for the API request, such as
 * "GET", "POST", "PUT", "DELETE", etc.
 * @param token - The `token` parameter is a string that represents an authentication token. It is used
 * to authorize the API request.
 * @param body - The `body` parameter is an object that contains the data to be sent in the request
 * body. It typically includes properties such as `username`, `password`, `name`, `lastname`, and
 * `method`. These properties are used to encrypt the data using the `encryptedaes256cbcTextPlain
 * @returns a Promise that resolves to the response data from the API call. If the environment variable
 * `REACT_APP_AAA` is set to `'true'`, the response data is decrypted using the `descryptedaes256cbc`
 * function before being returned.
 */
export const callApiRegister = async (url, method, token, body) => {
  const localToken = validateToken(localStorage.getItem('session'));
  if (process.env.REACT_APP_AAA === 'true') {
    body = {
      username: encryptedaes256cbcTextPlain(body.username, localToken),
      password: encryptedaes256cbcTextPlain(body.password, localToken),
      name: body.name
        ? encryptedaes256cbcTextPlain(body.name, localToken)
        : null,
      lastname: body.lastname
        ? encryptedaes256cbcTextPlain(body.lastname, localToken)
        : null,
      method: encryptedaes256cbcTextPlain(body.method, localToken),
    };
  }
  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
  });

  const json = responsecall.data;

  if (process.env.REACT_APP_AAA === 'true') {
    return await descryptedaes256cbc(json.data, localToken);
  }

  return json;
};

/**
 * The function `descryptedaes256cbc` takes an encrypted text and a private key, decrypts the text
 * using AES-256-CBC encryption, and returns the decrypted JSON object.
 * @param encryptedText - The `encryptedText` parameter is a string that represents the encrypted data.
 * It is expected to be in the format `IV:EncryptedData`, where `IV` is the initialization vector and
 * `EncryptedData` is the actual encrypted data. Both `IV` and `EncryptedData`
 * @param privateKey - The `privateKey` parameter is a string that represents the private key used for
 * decryption. It is used to create the decipher object for decrypting the encrypted data.
 * @returns the decrypted text as a JSON object.
 */
export function descryptedaes256cbc(encryptedText, privateKey) {
  const encryptedArray = encryptedText.split(':');
  const iv = Buffer.from(encryptedArray[0], 'hex');
  const encryptedData = Buffer.from(encryptedArray[1], 'hex');
  const decipher = createDecipheriv('aes-256-ctr', Buffer.from(privateKey), iv);
  let decrypted = decipher.update(encryptedData);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  const decryptedText = decrypted.toString();
  return JSON.parse(decryptedText);
}

/**
 * The function `getTokenView` takes a token as input and returns the result of validating the token.
 * @param token - The `token` parameter is a string that represents a token.
 * @returns the result of the `validateToken` function.
 */
export function getTokenView(token) {
  return validateToken(token);
}

/**
 * The function encryptedaes256cbc takes in data and a private key, and encrypts the data using
 * AES-256-CBC encryption with a randomly generated initialization vector (IV) and the private key.
 * @param data - The `data` parameter is the data that you want to encrypt. It can be any type of data,
 * but it will be converted to a JSON string before encryption.
 * @param privateKey - The `privateKey` parameter is a secret key used for encryption. It should be a
 * string or a buffer containing the key value.
 * @returns the encrypted text in the format of `${iv.toString('hex')}:${encrypted.toString('hex')}`.
 */
export function encryptedaes256cbc(data, privateKey) {
  try {
    const iv = randomBytes(16);
    // console.log(iv.toString("hex"), data, privateKey);
    const cipher = createCipheriv('aes-256-ctr', Buffer.from(privateKey), iv);
    let encrypted = cipher.update(JSON.stringify(data));
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    const encryptedText = `${iv.toString('hex')}:${encrypted.toString('hex')}`;

    return encryptedText;
  } catch (error) { }
}

/**
 * The function encrypts a plain text using AES-256-CBC encryption with a given private key.
 * @param data - The `data` parameter is the plain text that you want to encrypt using AES-256-CBC
 * encryption algorithm. It can be any string or binary data that you want to keep secure.
 * @param privateKey - The `privateKey` parameter is a secret key used for encryption. It should be a
 * string or a buffer containing the private key data.
 * @returns the encrypted text in the format of `${iv.toString('hex')}:${encrypted.toString('hex')}`.
 */
export function encryptedaes256cbcTextPlain(data, privateKey) {
  try {
    const iv = randomBytes(16);
    const cipher = createCipheriv('aes-256-ctr', Buffer.from(privateKey), iv);
    let encrypted = cipher.update(data);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    const encryptedText = `${iv.toString('hex')}:${encrypted.toString('hex')}`;
    return encryptedText;
  } catch (error) { }
}

/**
 * The function `validateToken` takes a token as input and returns a substring of the token by skipping
 * every 21st character.
 * @param token - The `token` parameter is a string that represents a token.
 * @returns the value of the variable `des`.
 */
function validateToken(token) {
  if (token) {
    let b = 0;
    let des = '';
    try {
      let dat = token;
      while (b < dat.length - 20) {
        des = des + dat.charAt(b);
        b = b + 21;
      }
      return des;
    } catch (error) { }
  }
}

/**
 * The function checks if the user is active by decrypting a value stored in local storage and
 * returning true if the decrypted value has a "private" property, otherwise it returns false.
 * @returns a boolean value. It returns true if the condition `local.private` is truthy, and false
 * otherwise.
 */
export function IsUserActive() {
  const localToken = getTokenView(localStorage.getItem('session'));

  if (localStorage.getItem('cea')) {
    let descrip = descryptedaes256cbc(localStorage.getItem('cea'), localToken);
    let local = descrip;
    // console.log(local)
    if (local.private) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * The function `UserLoginData` retrieves and decrypts user login data from local storage, returning
 * the display email if available.
 * @returns the value of `local.displayEmail` if `localStorage.getItem('cea')` exists and
 * `local.private` is truthy. Otherwise, it returns an empty string.
 */
export function UserLoginData() {
  const localToken = getTokenView(localStorage.getItem('session'));

  if (localStorage.getItem('cea')) {
    let descrip = descryptedaes256cbc(localStorage.getItem('cea'), localToken);
    let local = descrip;

    if (local.private) {
      return local.displayEmail;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function UserData() {
  const localToken = getTokenView(localStorage.getItem('session'));

  if (localStorage.getItem('cea')) {
    let descrip = descryptedaes256cbc(localStorage.getItem('cea'), localToken);
    let local = descrip;

    // console.log("USER", local)
    if (local.private) {
      return local;
    } else {
      return '';
    }
  } else {
    return '';
  }
}


export function setStoreID(storeID, name, brand) {
  const localToken = getTokenView(localStorage.getItem('session'));
  if (localStorage.getItem('cea')) {
    let descrip = descryptedaes256cbc(localStorage.getItem('cea'), localToken);
    let local = descrip;



    const newLocal = {
      ...local,
      storeId: storeID,
      storeLogo: brand,
      storeName: name
    }


    localStorage.setItem('cea', encryptedaes256cbc(newLocal, localToken));
    return true;

  } else {
    return '';
  }
}

